$( document ).ready(function() {
    $('a.zoombox1').zoombox();
    $('a.zoombox2').zoombox();
    $('a.zoombox3').zoombox();
    $('a.zoombox4').zoombox();
    $('a.zoombox5').zoombox();
    $('a.zoombox6').zoombox();
    $('a.zoombox7').zoombox();
    $('a.zoombox8').zoombox();
    $('a.zoombox9').zoombox();
    $('a.zoombox10').zoombox();
    $('a.zoombox11').zoombox();
    $('a.zoombox12').zoombox();
    $('a.zoombox13').zoombox();
      // $(".img-container").popupLightbox({
      //     width: 700,
      // height: 550
      //    });
        /*$(".fancybox").fancybox({
            maxWidth    : 700,
        maxHeight    : 500,
        fitToView    : false,
        width        : '60%',
        height        : '60%',
        autoSize    : false,
        closeClick    : false,
        openEffect    : 'none',
        closeEffect    : 'none'
    });*/
    $(".zoom").hover(function(){

        $(this).addClass('transition');
    }, function(){

        $(this).removeClass('transition');
    });
    $("#logo2").hide();
    $(window).scroll(function() {    
        var scroll = $(window).scrollTop();
        if (scroll >= 100) {
            $(".bg-faded").addClass("bg-solid");
            $("#logo").addClass("mt");
        }
        else{
            $(".bg-faded").removeClass("bg-solid");
            $("#logo").removeClass("mt");
        }
    });
    var sectionArray = [1, 2, 3, 4, 5];
    $.each(sectionArray, function(index, value){
        $('li a').eq(index).click(function(e){
            var offsetClick = $('#' + 'section_' + value).offset().top;
            e.preventDefault();
            $('html, body').animate({
                'scrollTop':offsetClick
            }, 300)
        });
    });
    $(document).ready(function(){
        $('li a:link').addClass('inactive');    
        $('li a').eq(0).addClass('active');
        $('li a:link').eq(0).removeClass('inactive');
    });
    new WOW().init();
});
$(document).on('ready', function() {
    if ($(window).width() < 960) {
        $(".regular").slick({
            dots: true,
            infinite: true,
            slidesToShow: 1, 
            slidesToScroll: 1
        });
    }
    else {
        $(".regular").slick({
            dots: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            autoplay: true
        });
    }
});




